<template>
	<v-layout column fill-height>
		<v-flex shrink>
			<v-tabs centered icons-and-text next-icon="mdi-arrow-right-bold-box-outline" prev-icon="mdi-arrow-left-bold-box-outline" show-arrows :value="activeTab">
				<v-tabs-slider color="primary"></v-tabs-slider>
				<v-tab v-for="section in menu" :key="section.key" @click="selectSection(section.route)">
					{{ section.title }}
					<v-icon>{{ section.icon }}</v-icon>
				</v-tab>
			</v-tabs>
		</v-flex>
		<v-flex scroll-y>
			<router-view />
		</v-flex>
	</v-layout>
</template>

<script>
import SuperAdminCatalogTreeStructureModuleGuard from '@/mixins/ModulesGuards/SuperAdmin/SuperAdminCatalogTreeStructureModuleGuard'
import HandleSideBarMixin from "@/mixins/SideBar/HandleSideBarMixin";
import NavDrawerTreeStructure from '@/components/SuperAdmin/Documents/NavDrawerTreeStructure'
import Vue from 'vue'


export default {
	name: 'DocumentsStructuresManager',
	mixins: [
		HandleSideBarMixin,
		SuperAdminCatalogTreeStructureModuleGuard,
	],
	data: function () {
		return {
			catalogTreeStructureId: null,
			menu: [
				{ icon: 'quiz', key: 1, route: 'sa-documents-manager-visualisation', title: this.$t('sa.geds.tabs_title.visualisation') },
				{
					icon: 'info',
					key: 2,
					route: 'sa-documents-manager-details',
					title: this.$t('sa.geds.tabs_title.details')
				}
			],
			route: null
		}
	},
	computed: {
		activeTab: function () {
			const routeName = this.$route.name
			let result = this.menu.findIndex(section => section.route == routeName)
			if (result == -1) {
				result = null
			}
			return result
		}
	},
	destroyed: function () {
		this.appEventBus.emit(this.appEvents.CLEAR_SIDEBAR_CONTENT, this);
		this.setHasSidebar(false);
		this.setPermanentSidebar(false);
	},
	mounted: function () {
		this.init()
	},
	methods: {
		selectSection: function (route) {
			this.route = route
			this.appService.goTo({name: route, params: {
					structureId: this.catalogTreeStructureId
				}
			})
		},
		getModuleEventsActionsMapping: function () {
			return [
				{ event: this.events.SELECT_CATALOG_TREE_STRUCTURE, action: this.setCatalogTreeStructureId }
			]
		},
		init: function () {
			const TreeStructuresClass = Vue.extend(NavDrawerTreeStructure)
			const TreeStructuresComponent = new TreeStructuresClass({
				parent: this
			})
			this.appEventBus.emit(this.appEvents.SET_SIDEBAR_CONTENT, {
				canMinimize: false,
				moduleInstance: TreeStructuresComponent,
				emitter: this
			})
			this.setHasSidebar(true);
			this.setPermanentSidebar(true);
		},
		setCatalogTreeStructureId: function (catalogTreeStructure) {
			this.catalogTreeStructureId = catalogTreeStructure.id
			this.appService.goTo({name: this.route, params: {
					structureId: this.catalogTreeStructureId
				}
			})
		}
	}
}
</script>
