<template>
	<v-layout column fill-height>
		<v-flex scroll-y>
			<v-layout v-if="loading" align-center class="w-loading-container" fill-height justify-center>
				<v-progress-circular color="primary" indeterminate size="50" width="6" />
			</v-layout>
			<v-list v-else style="max-width:100%">
				<v-list-tile
					v-for="(catalogTreeStructure, index) in catalogTreeStructures"
					:key="catalogTreeStructure.id"
					active-class="primary--text"
					:value="treeStructureSelected && catalogTreeStructure.id == treeStructureSelected.id"
					@click="selectCatalogTreeStructure(catalogTreeStructure)"
					@mouseover="hover = index"
					@mouseout="hover = null"
					
				>
					<v-list-tile-content>
						<v-list-tile-title v-text="catalogTreeStructure.name" />
					</v-list-tile-content>
					<v-fade-transition>
						<v-list-tile-avatar v-if="hover == index" right>
							<w-btn flat icon="settings" mini mx-0 px-0 @click="updateCatalogTreeStructure(catalogTreeStructure)">
								{{ $t('sa.geds.update_catalog_tree_structure') }}
							</w-btn>
							<w-btn-delete mini mx-0 px-0 @click="deleteCatalogTreeStructure(catalogTreeStructure)" />
						</v-list-tile-avatar>
					</v-fade-transition>
				</v-list-tile>
			</v-list>
		</v-flex>
		<v-flex shrink>
			<w-btn full-width icon="add" @click="createCatalogTreeStructure()">{{ $t('sa.geds.create_catalog_tree_structure') }}</w-btn>
		</v-flex>
		<DialogCatalogTreeStructure
			v-model="catalogTreeStructureToUpdate"
			:action="mode"
			:open="open"
			@add="addCatalogTreeStructure($event)"
			@close="open = false"
			@input="modifyCatalogTreeStructure($event)"
		/>
	</v-layout>
</template>

<script>
import SuperAdminCatalogTreeStructureModuleGuard from '@/mixins/ModulesGuards/SuperAdmin/SuperAdminCatalogTreeStructureModuleGuard'
export default {
	name: 'NavDrawerTreeStructure',
	components: {
		DialogCatalogTreeStructure: () => ({
			component: import('@/components/SuperAdmin/Documents/DialogCatalogTreeStructure')
		})
	},
	mixins: [SuperAdminCatalogTreeStructureModuleGuard],
	data: function () {
		return {
			catalogTreeStructures: [],
			catalogTreeStructureToUpdate: {},
			hover: null,
			loading: false,
			mode: null,
			open: false,
			treeStructureSelected: null
		}
	},
	mounted: function () {
		this.loadCatalogTreeStructures()
	},
	methods: {
		addCatalogTreeStructure: function (newCatalogTreeStructure) {
			this.catalogTreeStructures.push(newCatalogTreeStructure)
		},
		createCatalogTreeStructure: function () {
			this.mode = 'create'
			this.open = true
		},
		deleteCatalogTreeStructure: function (catalogTreeStructure) {
			this.$dialog
				.warning({
					text: this.$t('actions.are_you_sure'),
					title: this.$t('sa.geds.delete_catalog_tree_structure'),
					actions: {
						false: this.$t('actions.no'),
						true: this.$t('actions.yes')
					}
				})
				.then(res => {
					if (res) {
						this.service
							.deleteCatalogTreeStructure(catalogTreeStructure.id)
							.then(() => this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('sa.geds.tree_structure_deleted')))
							.then(() => {
								this.catalogTreeStructures = this.catalogTreeStructures.filter(item => item.id !== catalogTreeStructure.id)
							})
							.then(() => {
								this.selectCatalogTreeStructure(this.catalogTreeStructures[0])
							})
					}
				})
		},
		loadCatalogTreeStructures: function () {
			this.loading = true
			this.service.listCatalogTreeStructures().then(catalogTreeStructures => {
				catalogTreeStructures.forEach((catalogTreeStructure, index) => {
					this.catalogTreeStructures.push(catalogTreeStructure)
					if (index == 0) {
						this.selectCatalogTreeStructure(catalogTreeStructure)
					}
				})
			}).finally(() => {
				this.loading = false
			})
		},
		modifyCatalogTreeStructure: function (catalogTreeStructureUpdated) {
			this.catalogTreeStructures = this.catalogTreeStructures.map(item => {
				let result = null
				if (item.id == catalogTreeStructureUpdated.id) {
					result = catalogTreeStructureUpdated
				} else {
					result = item
				}
				return result
			})
		},
		selectCatalogTreeStructure: function (catalogTreeStructure) {
			this.treeStructureSelected = catalogTreeStructure
			this.eventBus.emit(this.events.SELECT_CATALOG_TREE_STRUCTURE, catalogTreeStructure)
		},
		updateCatalogTreeStructure: function (catalogTreeStructure) {
			this.mode = 'update'
			this.catalogTreeStructureToUpdate = catalogTreeStructure
			this.open = true
		}
	}
}
</script>
<style>
.active-ged {
	background-color: rgba(var(--v-primary-transparent), 0.25) !important;
	min-width: max-content;
}

</style>